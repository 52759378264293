import { Fragment, useState } from 'react';
import CourseCard from '../../shared/shared-card/CourseCard';
import { PLAN_TYPE } from '@lib/constants';
import DarkLayout from '@components/shared/DarkLayout';
import NewSectionTitle from '@components/shared/NewSectionTitle';
import { Secondary, SecondaryColor } from 'stories/Button.stories';
import useMediaQuery from '@utils/hooks/useMediaQuery';

export const PLAN_TYPE_OPTIONS = {
  All: 'All',
  masters_degree: "Master's Degree",
  pg_certification: 'PG Certifications',
  // ug_course: 'UG Courses',
};

const FeaturedCourses = ({ featuredCoursesInitials, featuredCoursesList }) => {
  const [active, setActive] = useState('All');

  const { heading, description } = featuredCoursesInitials;
  const reversedCourseList = featuredCoursesList?.reverse();
  const filteredData =
    active === 'All'
      ? reversedCourseList
      : reversedCourseList.filter((item) => item.attributes.planType === active);

  return (
    <div id="featuredCourses">
      <DarkLayout
        customStyle="!flex flex-col items-center gap-y-[72px] px-[16px] py-[40px] md:px-[124px] md:py-[80px]"
        gridMode
      >
        <div className="flex flex-col items-center gap-y-[16px]">
          <NewSectionTitle
            text={'Explore Our'}
            highlightedText={'Courses'}
            headingStyle={'text-white'}
          />
          <p className="text-center font-satoshi-regular text-[14px] leading-[20px] text-[#A3A3A3] md:text-[18px] md:leading-7">
            {description}
          </p>
        </div>
        <div className="relative z-10 flex flex-col gap-y-[40px]">
          <div className="flex w-full flex-wrap items-center justify-center gap-[14px]">
            {Object.entries(PLAN_TYPE_OPTIONS).map(([key, value]) => (
              <Fragment key={key}>
                {key === active ? (
                  <SecondaryColor label={value} size={'large'} />
                ) : (
                  <Secondary
                    customStyle={
                      '!bg-[#ffffff1a] !text-white hover:!bg-[#ffffff1a] !border-none !outline-none'
                    }
                    onClick={() => setActive(key)}
                    label={value}
                    size={'large'}
                  />
                )}
              </Fragment>
            ))}
          </div>
          <div className={`grid place-items-center gap-[64px] md:grid-cols-2 md:px-[124px]`}>
            {filteredData.map((item, index) => (
              <CourseCard
                homepage
                key={item.id}
                course={item.attributes}
                index={index}
                newLayout
                horizontalView={
                  item.attributes.planType === PLAN_TYPE.MASTERS_DEGREE &&
                  featuredCoursesList.length === 3
                }
              />
            ))}
          </div>
        </div>
      </DarkLayout>
    </div>
  );
};

export default FeaturedCourses;
